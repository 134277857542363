@charset "utf-8";
/* ==========================================================================
 Foundation
========================================================================== */
.u-display--sp {
  display: block;
}
@media screen and (min-width: 767px) {
  .u-display--sp {
    display: none;
  }
}
.u-display--pc {
  display: none;
}
@media screen and (min-width: 767px) {
  .u-display--pc {
    display: block;
  }
}
p,
h1,
h2,
h3,
dl,
dd {
  margin: 0;
}
button {
  padding: 0;
  border: none;
}
button:focus {
  outline: none;
}
button:hover {
  cursor: pointer;
}
body {
  margin: 0;
}
ul,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}
em {
  font-style: normal;
}
img {
  vertical-align: top;
}
@font-face {
  font-family: 'alte Din';
  src: url("../font/Alte DIN 1451 Mittelschrift gepraegt Regular/Alte DIN 1451 Mittelschrift gepraegt Regular.ttf");
}
* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #333;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: "Noto Sans JP", -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
  -webkit-text-size-adjust: 100%;
}
body.is-fixed {
  position: fixed;
}
a {
  cursor: pointer;
}
table {
  border-collapse: collapse;
}
dl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
ul {
  list-style: none;
}
select,
textarea {
  background-color: #fff;
}
select::-ms-expand {
  display: none;
}
.selectMain__placeholder {
  display: none;
}
.item--pc {
  display: none;
}
@media screen and (min-width: 767px) {
  .item--pc {
    display: block;
  }
}
.item--sp {
  display: block;
}
@media screen and (min-width: 767px) {
  .item--sp {
    display: none;
  }
}
/* ==========================================================================
 Layout
========================================================================== */
.l-header {
  position: fixed;
  width: 100%;
  padding: 15px 20px;
  z-index: 1;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
@media screen and (min-width: 767px) {
  .l-header {
    padding: 20px 40px;
    padding: 20px clamp(5px, 2.777777777777778vw, 40px);
  }
}
.l-header.active {
  background: #fff;
  -webkit-box-shadow: 0px 8px 8px rgba(0, 0, 0, .04);
          box-shadow: 0px 8px 8px rgba(0, 0, 0, .04);
}
.l-header__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 5px;
}
.l-header__logoWrap img {
  display: block;
}
@media screen and (min-width: 767px) {
  .l-header__logoWrap img {
    width: clamp(100px, 15.625vw, 225px);
  }
}
.l-header__contentsWrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 32px;
}
.l-header__navLists {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 40px;
  gap: clamp(1px, 2.777777777777778vw, 40px);
  height: 100%;
}
.l-header__navList > a {
  font-weight: 700;
  font-size: 16px;
  color: #1a1a1a;
  text-decoration: none;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
@media screen and (min-width: 767px) {
  .l-header__navList > a {
    font-size: clamp(14px, 1.111111111111111px, 16px);
  }
}
.l-header__navList > a:hover {
  opacity: 0.7;
}
.l-header__nav {
  display: none;
}
@media screen and (min-width: 767px) {
  .l-header__nav {
    display: block;
  }
}
/* ==========================================================================
 Object
========================================================================== */
/* -----------------------------------------------------------------
 Component
----------------------------------------------------------------- */
.c-buttonPrimary {
  position: relative;
  display: block;
  width: 100%;
  background: -webkit-gradient(linear, left top, right top, color-stop(-9.84%, #0e73f0), to(#35ffaf)), #333;
  background: linear-gradient(90deg, #0e73f0 -9.84%, #35ffaf 100%), #333;
  border-radius: 50px;
  -webkit-box-shadow: 0px 15px 40px rgba(8, 159, 225, .25);
          box-shadow: 0px 15px 40px rgba(8, 159, 225, .25);
  padding: 22px 30px;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
  color: #fff;
  text-decoration: none;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
@media screen and (min-width: 767px) {
  .c-buttonPrimary {
    padding: 22px 70px;
  }
}
.c-buttonPrimary > span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: absolute;
  top: 50%;
  right: 14px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.c-buttonPrimary > span > span {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 50%;
  border: 3px solid #93f9d9;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.c-buttonPrimary > span > span > span:nth-of-type(1) {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  width: 14px;
  height: 2px;
  background: #1add9c;
  border-radius: 8px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.c-buttonPrimary > span > span > span:nth-of-type(2) {
  position: absolute;
  content: "";
  top: 41%;
  right: 9px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  width: 7px;
  height: 2px;
  background: #1add9c;
  border-radius: 8px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.c-buttonPrimary > span > span > span:nth-of-type(3) {
  position: absolute;
  content: "";
  top: 53%;
  right: 9px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  width: 7px;
  height: 2px;
  background: #1add9c;
  border-radius: 8px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.c-buttonPrimary:hover {
  opacity: 0.7;
}
.c-buttonPrimary:hover > span > span {
  background: #93f9d9;
  -webkit-transform: scale(1.15, 1.15);
          transform: scale(1.15, 1.15);
}
.c-buttonPrimary:hover > span > span > span {
  background: #fff;
}
.c-buttonPrimary:hover > span > span > span:nth-of-type(1) {
  left: 56%;
  -webkit-transform: translateX(-50%) translateY(-50%) scale(0.86, 0.86);
          transform: translateX(-50%) translateY(-50%) scale(0.86, 0.86);
}
.c-buttonPrimary:hover > span > span > span:nth-of-type(2) {
  right: 7px;
  -webkit-transform: rotate(45deg) scale(0.86, 0.86);
          transform: rotate(45deg) scale(0.86, 0.86);
}
.c-buttonPrimary:hover > span > span > span:nth-of-type(3) {
  right: 7px;
  -webkit-transform: rotate(-45deg) scale(0.86, 0.86);
          transform: rotate(-45deg) scale(0.86, 0.86);
}
.c-buttonSecondary {
  display: block;
  padding: 7px 32px;
  background: -webkit-gradient(linear, left top, right top, from(#0084fe), to(#1be394));
  background: linear-gradient(90deg, #0084fe 0%, #1be394 100%);
  -webkit-box-shadow: 0px 10px 10px rgba(8, 159, 225, .16);
          box-shadow: 0px 10px 10px rgba(8, 159, 225, .16);
  border-radius: 50px;
  font-weight: 700;
  font-size: 18px;
  color: #fff;
  text-decoration: none;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.c-buttonSecondary:hover {
  opacity: 0.7;
}
.formRow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  padding: 10px 0 20px;
}
.formRow:last-of-type {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 40px;
}
.formRow--select {
  padding: 15px 0;
}
.formRow--select:last-of-type {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 30px;
}
.formRow__que {
  height: auto;
  width: 100%;
  max-width: 280px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.formRow__que--secondary {
  width: 100%;
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.formRow__que__main {
  font-size: 20px;
  text-align: left;
  font-weight: normal;
}
.formRow__que__main--secondary {
  margin-left: 15px;
  font-size: 20px;
}
.formRow__que__label--required,
.formRow__que__label--any {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 70px;
  height: 32px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 6px;
  color: #fff;
  border: none;
  font-size: 14px;
}
.formRow__que__label--required {
  background: #f97d7d;
  margin-right: 15px;
}
.formRow__que__label--any {
  background: #7eb800;
  margin-right: 15px;
}
.formRow__ans {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 10px;
  width: 100%;
  min-width: 504px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.formRow__ans--select {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}
.formRow__que__annotation {
  color: #808080;
}
.inputMain,
.inputSecondary,
.selectMain,
.selectSecondary,
.selectTertiary {
  height: 50px;
  padding: 0 19px;
  border-radius: 3px;
  border: 1px solid #b1b1b1;
  font-size: 16px;
  font-family: inherit;
}
.inputMain::-webkit-input-placeholder,
.inputSecondary::-webkit-input-placeholder,
.selectMain::-webkit-input-placeholder,
.selectSecondary::-webkit-input-placeholder,
.selectTertiary::-webkit-input-placeholder {
  color: #c4c4c4;
}
.inputMain::-moz-placeholder,
.inputSecondary::-moz-placeholder,
.selectMain::-moz-placeholder,
.selectSecondary::-moz-placeholder,
.selectTertiary::-moz-placeholder {
  color: #c4c4c4;
}
.inputMain::-ms-input-placeholder,
.inputSecondary::-ms-input-placeholder,
.selectMain::-ms-input-placeholder,
.selectSecondary::-ms-input-placeholder,
.selectTertiary::-ms-input-placeholder {
  color: #c4c4c4;
}
.inputMain::placeholder,
.inputSecondary::placeholder,
.selectMain::placeholder,
.selectSecondary::placeholder,
.selectTertiary::placeholder {
  color: #c4c4c4;
}
.inputMain {
  width: 100%;
}
.inputSecondary {
  width: 162px;
}
.selectMain {
  width: 100%;
}
.selectBox,
.selectBox--short {
  position: relative;
}
.selectBox:after,
.selectBox--short:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 11px solid #393939;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
}
.selectBox {
  width: 100%;
}
.selectBox:after {
  right: 25px;
}
.selectBox--short:after {
  right: 14px;
}
.selectSecondary {
  width: 140px;
}
.selectTertiary {
  width: 70px;
}
.formRow__ans__chara {
  display: inline-block;
  font-weight: bold;
  height: 23px;
  margin: 0 28.6px 0 12px;
}
.radioSet {
  margin-right: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.radioSet__button {
  margin-right: 15px;
}
.textareaMain {
  width: 100%;
  height: 200px;
  padding: 10px;
  border: 1px solid #b1b1b1;
  border-radius: 3px;
  font-size: 16px;
}
.textareaMain::-webkit-input-placeholder {
  font-size: 16px;
  color: #b7b7b7;
}
.textareaMain::-moz-placeholder {
  font-size: 16px;
  color: #b7b7b7;
}
.textareaMain::-ms-input-placeholder {
  font-size: 16px;
  color: #b7b7b7;
}
.textareaMain::placeholder {
  font-size: 16px;
  color: #b7b7b7;
}
.formErrorTxt {
  color: #f00;
  margin-left: 10px;
  display: none;
}
.formErrorTxtSecondary {
  color: #f00;
  margin-left: 10px;
}
.form__caution {
  margin-top: 45px;
  font-size: 16px;
}
.formSection__info {
  margin-top: 10px;
}
.formSection__info__head {
  font-size: 12px;
  font-weight: bold;
}
.formSection__info__txt {
  font-size: 12px;
  margin-top: 8px;
}
@media screen and (max-width: 960px) {
  .formRow {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 0 0 21.5px;
    margin-bottom: 0;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    width: 100%;
  }
  .formRow:last-of-type {
    padding-bottom: 50px;
    margin-bottom: 0;
  }
  .formRowfirst-of-type {
    padding-top: 0;
  }
  .formRow__que {
    margin-top: 0;
    padding: 0 17px;
  }
  .formRow__ans {
    max-width: none;
    padding: 0 17px;
    margin-top: 12.5px;
    min-width: 0;
  }
  .formRow__que__main {
    margin-left: 15px;
    font-weight: bold;
  }
  .formRow__que__main--secondary {
    margin-left: 0px;
    margin-right: 15px;
    width: 80%;
    text-align: left;
    font-size: 14px;
  }
}
@media screen and (max-width: 767px) {
  .formRow__que__label--required {
    width: 40px;
    height: 20px;
    font-size: 12px;
    line-height: 20px;
  }
  .formRow__que__label--any {
    width: 45px;
    height: 20px;
    font-size: 10px;
    line-height: 20px;
  }
  .formRow__que__main {
    font-size: 14px;
    margin-left: 0;
    margin-right: 15px;
    font-weight: bold;
  }
  .formRow__ans {
    max-width: none;
    padding: 0 17px;
    margin-top: 12.5px;
    min-width: 0;
  }
  .formRow__ans__chara {
    margin: 0 17px 0 5px;
  }
  .formRow__ans__chara:last-of-type {
    margin-right: 0;
  }
  .inputMain,
  .inputSecondary,
  ._selectBase,
  .selectMain {
    font-size: 14px;
  }
  .inputSecondary {
    width: 100%;
  }
  .selectSecondary {
    width: 97px;
  }
  .selectTertiary {
    width: 55.5px;
  }
  .radioSet {
    margin-right: 0;
    font-size: 16px;
  }
  .radioSet:first-of-type {
    margin: 0 130px 5px 0;
  }
  .radioSet__button {
    margin-right: 15px;
  }
  .textareaMain {
    height: 188.8px;
  }
  .formRow__que--secondary {
    width: 100%;
    width: 100%;
    height: auto;
    margin: 10px auto;
    padding: 0 27px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .formRow__que__annotation {
    font-size: 12px;
  }
}
/* -----------------------------------------------------------------
 Project
----------------------------------------------------------------- */
.aboutBlock {
  margin-top: 32px;
  padding: 0 20px;
}
@media screen and (min-width: 767px) {
  .aboutBlock {
    padding: 0px;
    max-width: 1440px;
    margin: auto;
  }
}
.aboutText {
  margin-top: 32px;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
}
@media screen and (min-width: 767px) {
  .aboutText {
    margin-top: 48px;
  }
}
.aboutPointList {
  margin-top: 18px;
}
.aboutPointList:first-child {
  margin-top: 50px;
}
.aboutCard__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
@media screen and (min-width: 767px) {
  .aboutCard__head {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    gap: 24px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
.aboutPointBlock,
.aboutPointBlock--secondary,
.aboutPointBlock--tertiary {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}
@media screen and (min-width: 767px) {
  .aboutPointBlock,
  .aboutPointBlock--secondary,
  .aboutPointBlock--tertiary {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: right;
        -ms-flex-pack: right;
            justify-content: right;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 80px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .aboutPointBlock,
  .aboutPointBlock--secondary,
  .aboutPointBlock--tertiary {
    -webkit-box-pack: right;
        -ms-flex-pack: right;
            justify-content: right;
    margin-left: 20px;
  }
}
.aboutPointBlock--secondary {
  margin-top: 40px;
}
@media screen and (min-width: 767px) {
  .aboutPointBlock--secondary {
    margin-top: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
    -webkit-box-pack: left;
        -ms-flex-pack: left;
            justify-content: left;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .aboutPointBlock--secondary {
    -webkit-box-pack: left;
        -ms-flex-pack: left;
            justify-content: left;
    margin-right: 20px;
  }
}
.aboutPointBlock--tertiary {
  margin-top: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media screen and (min-width: 767px) {
  .aboutPointBlock--tertiary {
    margin-top: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}
.aboutCard {
  padding-left: 32px;
  border-left: 3px solid #028ef7;
}
.aboutCard--secondary {
  padding-right: 32px;
  border-right: 3px solid #028ef7;
}
.aboutCard__Icon {
  width: 73px;
}
@media screen and (min-width: 767px) {
  .aboutCard__Icon {
    width: 130px;
  }
}
.aboutImg--primary img {
  margin: 0 calc(50% - 50vw);
  width: 100vw;
  max-width: none;
}
@media screen and (min-width: 767px) {
  .aboutImg--primary img {
    margin: 0;
    max-width: 600px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .aboutImg--primary img {
    max-width: 310px;
  }
}
.aboutImg--secandary img,
.aboutImg--tertiary img {
  max-width: none;
  margin: 0 calc(50% - 50vw);
  width: 100vw;
}
@media screen and (min-width: 767px) {
  .aboutImg--secandary img,
  .aboutImg--tertiary img {
    margin: 0;
    max-width: 600px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .aboutImg--secandary img,
  .aboutImg--tertiary img {
    max-width: 310px;
  }
}
.aboutCard__title {
  margin-top: 16px;
  font-size: 22px;
  color: #1a1a1a;
}
@media screen and (min-width: 767px) {
  .aboutCard__title {
    font-size: 26px;
  }
}
.aboutCard__list {
  margin-top: 24px;
}
.aboutCard__list li {
  margin-top: 16px;
  font-size: 16px;
  position: relative;
  padding-left: 15px;
}
.aboutCard__list li:first-of-type {
  margin-top: 0px;
}
.aboutCard__list li::before {
  content: "";
  position: absolute;
  top: 9px;
  left: 0;
  width: 9px;
  height: 9px;
  background-color: #028bf7;
  border-radius: 50%;
}
.about__btnWrap {
  max-width: 320px;
  margin-top: 178px;
}
@media screen and (min-width: 767px) {
  .about__btnWrap {
    margin: 40px auto 0;
  }
}
.about__btnWrap > .c-buttonPrimary {
  padding: 23px 61px 25px 40px;
  line-height: 1;
}
.about-page__formBtn {
  width: 100%;
  padding: 23px 30px;
  background: -webkit-gradient(linear, left top, right top, color-stop(-9.84%, #0e73f0), to(#35ffaf)), #333;
  background: linear-gradient(90deg, #0e73f0 -9.84%, #35ffaf 100%), #333;
  border-radius: 50px;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
  color: #fff;
  text-decoration: none;
}
@media screen and (min-width: 767px) {
  .about-page__formBtn {
    width: 395px;
  }
}
.about-page__formBtn a {
  color: #fff;
  text-decoration: none;
}
.about-page__formBtn:hover {
  opacity: 0.5;
}
.service-faqWrap {
  margin: 30px auto 0;
  padding: 0 20px;
  max-width: 1040px;
}
@media screen and (min-width: 767px) {
  .service-faqWrap {
    margin: 80px auto 0;
    padding: 0px;
  }
}
.service-faqBlock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
}
.service-faqBlock:not(:first-child) {
  margin-top: 15px;
}
@media screen and (min-width: 767px) {
  .service-faqBlock:not(:first-child) {
    margin-top: 20px;
  }
}
.service-faqHead {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  padding: 14px 35px 14px 19px;
  position: relative;
  cursor: pointer;
  border-bottom: 2px solid #dff2ff;
}
@media screen and (min-width: 767px) {
  .service-faqHead {
    font-size: 18px;
  }
}
.service-faqHead .service-arrow {
  display: inline-block;
  vertical-align: middle;
  color: #028bf7;
  line-height: 1;
  position: absolute;
  top: 23px;
  right: 15px;
  width: 13px;
  height: 13px;
  -webkit-transform: translateX(-25%) rotate(-225deg);
          transform: translateX(-25%) rotate(-225deg);
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
@media screen and (min-width: 767px) {
  .service-faqHead .service-arrow {
    top: 26px;
  }
}
.service-faqHead .service-arrow:before {
  content: '';
  position: absolute;
  background: currentColor;
  border-radius: 0.1em;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
}
.service-faqHead .service-arrow:after {
  content: '';
  position: absolute;
  background: currentColor;
  border-radius: 0.1em;
  top: 0;
  right: 0;
  bottom: 0;
  width: 2px;
}
.service-faqHead.active .service-arrow {
  -webkit-transform: translateX(-25%) rotate(-45deg);
          transform: translateX(-25%) rotate(-45deg);
}
.service-faqHead__title {
  max-width: 260px;
}
@media screen and (min-width: 767px) {
  .service-faqHead__title {
    max-width: none;
  }
}
@media screen and (max-width: 375px) {
  .service-faqHead__title {
    max-width: 220px;
  }
}
.service-faqBody {
  padding: 0px 19px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.service-faqBody__inner {
  margin-top: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
  font-size: 15px;
  font-weight: 500;
}
.service-faqBody__text {
  max-width: 280px;
}
@media screen and (min-width: 767px) {
  .service-faqBody__text {
    max-width: none;
  }
}
.iconWrap,
.iconWrap--ans {
  width: 35px;
  height: 35px;
  background: #dff2ff;
  position: relative;
  border-radius: 50%;
}
@media screen and (min-width: 767px) {
  .iconWrap,
  .iconWrap--ans {
    width: 42px;
    height: 42px;
  }
}
.iconWrap--ans {
  background: #e0fdf2;
}
.c-icon--service-que,
.c-icon--service-ans {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.flowBlock {
  padding: 0 20px;
}
.flowText {
  margin-top: 32px;
  text-align: center;
  font-size: 16px;
  line-height: 1.5;
}
.flowImg {
  margin: 60px auto 0;
  display: block;
  text-align: center;
  width: 100%;
}
@media screen and (max-width: 1024px) {
  .flowImg img {
    max-width: 750px;
  }
}
.flowList {
  margin: 40px auto;
  max-width: 1040px;
}
@media screen and (max-width: 1024px) {
  .flowList {
    max-width: 750px;
  }
}
.flowList__img {
  max-width: 120px;
  width: 100%;
}
.flowList__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 40px;
}
@media screen and (min-width: 767px) {
  .flowList__item {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    gap: 54px;
  }
}
.flowList__item:first-child {
  margin-top: 0px;
}
.flowList__title {
  margin-top: 16px;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
}
@media screen and (min-width: 767px) {
  .flowList__title {
    margin-top: 0px;
    text-align: start;
    font-size: 22px;
  }
}
.flowList__text {
  margin-top: 16px;
  font-size: 14px;
}
@media screen and (min-width: 767px) {
  .flowList__text {
    margin-top: 24px;
    font-size: 16px;
  }
}
.footerBlock {
  padding: 40px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #028bf7;
}
.footerBlock small {
  margin-top: 54px;
  font-size: 13px;
  color: #fff;
}
@media screen and (min-width: 767px) {
  .footerBlock {
    padding: 27px 170px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .footerBlock small {
    margin-top: 0px;
  }
}
@media screen and (max-width: 1024px) {
  .footerBlock {
    padding: 27px 20px;
  }
}
.footerBlock__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 24px;
}
@media screen and (min-width: 767px) {
  .footerBlock__inner {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    gap: 80px;
  }
}
@media screen and (min-width: 767px) {
  .l-header__logoWrap img {
    width: clamp(100px, 15.625vw, 225px);
  }
}
.footerLink {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 25px;
}
.footerLink a {
  text-decoration: none;
  font-size: 16px;
  color: #fff;
}
.functionBlock {
  padding: 0 20px;
}
.functionsItem {
  padding: 30px 20px;
  width: 100%;
  background-color: #fff;
}
@media screen and (min-width: 767px) {
  .functionsItem {
    max-width: 350px;
  }
}
.functionsItem__title {
  font-size: 18px;
  font-weight: 700;
}
@media screen and (min-width: 767px) {
  .functionsItem__title {
    font-size: 20px;
  }
}
.functionsItem__text {
  font-size: 14px;
}
@media screen and (min-width: 767px) {
  .functionsItem__text {
    font-size: 16px;
  }
}
.kv {
  position: relative;
  overflow: hidden;
}
.kv__bgPrimary {
  position: absolute;
  z-index: -4;
}
.kv__bgPrimary img {
  width: 100vw;
  height: 100vh;
}
.kv__bgSecondary {
  position: absolute;
  bottom: 0;
  left: 56%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: -3;
}
@media screen and (min-width: 767px) {
  .kv__bgSecondary {
    left: 50%;
    bottom: -20%;
  }
}
@media screen and (min-width: 767px) {
  .kv__bgSecondary img {
    width: 100vw;
    max-width: 1440px;
  }
}
.kv__bgTertiary {
  position: absolute;
  top: 280px;
  left: 55%;
  -webkit-transform: translateX(-28%);
          transform: translateX(-28%);
  z-index: -2;
}
@media screen and (min-width: 767px) {
  .kv__bgTertiary {
    top: 90px;
    left: 71%;
    width: 500px;
  }
}
@media screen and (min-width: 1024px) {
  .kv__bgTertiary {
    left: 61%;
  }
}
.kv__bgQuaternary {
  position: absolute;
  bottom: 0;
  z-index: -1;
}
.kv__bgQuaternary img {
  width: 100vw;
}
.kv__inner {
  width: 100%;
  max-width: 375px;
  padding: 75px 20px 58px;
  margin: 0 auto;
}
@media screen and (min-width: 767px) {
  .kv__inner {
    max-width: 1080px;
    padding: 150px 20px 168px;
  }
}
.kv__title {
  font-weight: 700;
  font-size: 43px;
  line-height: 1.4;
  background: -webkit-gradient(linear, right top, left top, from(#001d5e), to(#0077e7));
  background: linear-gradient(270deg, #001d5e 0%, #0077e7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media screen and (min-width: 767px) {
  .kv__title {
    font-size: clamp(50px, 5.138888888888888vw, 74px);
  }
}
.kv__sloganWrap {
  margin-top: 18px;
}
@media screen and (min-width: 767px) {
  .kv__sloganWrap {
    margin-top: 8px;
  }
}
.kv__slogan {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.4;
  color: #222;
}
@media screen and (min-width: 767px) {
  .kv__slogan {
    font-size: 36px;
    font-size: clamp(30px, 2.5vw, 36px);
    line-height: 1.5;
  }
}
.kv__descriptionWrap {
  margin-top: 18px;
}
@media screen and (min-width: 767px) {
  .kv__descriptionWrap {
    margin-top: 24px;
  }
}
.kv__description {
  font-weight: 500;
  font-size: 15px;
  line-height: 1.4;
  color: #333;
}
@media screen and (min-width: 767px) {
  .kv__description {
    font-size: 20px;
  }
}
.kv__btnWrap {
  max-width: 320px;
  margin-top: 200px;
}
@media screen and (min-width: 767px) {
  .kv__btnWrap {
    margin-top: 40px;
  }
}
.kv__btnWrap > .c-buttonPrimary {
  padding: 23px 56px 25px 35px;
  line-height: 1;
}
.productsImgwrap {
  margin-top: 55px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 50px;
}
@media screen and (min-width: 767px) {
  .productsImgwrap {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}
.recommendBlock {
  padding: 0 20px;
}
.recommendList {
  margin-top: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media screen and (min-width: 767px) {
  .recommendList {
    margin-top: 65px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    gap: 50px;
  }
}
@media screen and (max-width: 1024px) {
  .recommendList {
    gap: 15px;
  }
}
.recommendList__item {
  margin: 30px auto 0;
  position: relative;
  border-radius: 50%;
  width: 240px;
  height: 240px;
  background-color: #fff;
}
.recommendList__item:first-child {
  margin-top: 0px;
}
.recommendList__item:nth-child(3) {
  margin-top: 60px;
}
@media screen and (min-width: 767px) {
  .recommendList__item:nth-child(3) {
    margin-top: 0px;
  }
}
@media screen and (min-width: 767px) {
  .recommendList__item {
    margin: 0;
  }
}
.recommendList__text {
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  color: #1a1a1a;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
}
.recommendList__img {
  display: block;
  margin: auto;
  position: absolute;
  top: -10px;
  left: 50%;
  -webkit-transform: translate(-50%, -25%);
          transform: translate(-50%, -25%);
}
.recommendList__img--primary,
.recommendList__img--secondary {
  display: block;
  margin: auto;
  position: absolute;
  top: 20px;
  left: 50%;
  -webkit-transform: translate(-50%, -25%);
          transform: translate(-50%, -25%);
}
.recommendList__img--secondary {
  top: -10px;
}
.supportsBlock {
  padding: 0 20px;
  margin-top: 32px;
}
@media screen and (min-width: 767px) {
  .supportsBlock {
    margin-top: 40px;
  }
}
.supportsText {
  font-size: 16px;
  text-align: center;
}
.supportsCardWrap {
  margin-top: 47px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media screen and (min-width: 767px) {
  .supportsCardWrap {
    margin-top: 74px;
    gap: 50px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
.supportsCard {
  position: relative;
}
.supportsCard__img {
  position: absolute;
  top: -30%;
  left: 20%;
}
.supportsImg--primary {
  margin: auto;
}
.supportsImg--primary img {
  max-width: 350px;
}
@media screen and (min-width: 767px) {
  .supportsImg--primary img {
    max-width: 600px;
  }
}
@media screen and (max-width: 1024px) {
  .supportsImg--primary img {
    max-width: 330px;
  }
}
@media screen and (min-width: 767px) {
  .supportsImg--primary {
    margin: 0;
  }
}
.supportsCard__inner {
  margin-top: 19px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 8px;
}
@media screen and (min-width: 767px) {
  .supportsCard__inner {
    margin-top: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    gap: 16px;
  }
}
.supportsCard__title {
  font-size: 22px;
}
@media screen and (min-width: 767px) {
  .supportsCard__title {
    font-size: 26px;
  }
}
.supportsCard__subTitle {
  margin-top: 24px;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  color: #1a1a1a;
}
@media screen and (min-width: 767px) {
  .supportsCard__subTitle {
    margin-top: 42px;
    text-align: start;
    font-size: 20px;
  }
}
.supportsCard__list {
  margin-top: 16px;
}
.supportsCard__list li {
  margin-top: 8px;
}
.supportsCard__list li:first-child {
  margin-top: 0px;
}
.supportsCard__Icon {
  width: 40px;
}
@media screen and (min-width: 767px) {
  .supportsCard__Icon {
    width: 50px;
  }
}
.formContainer {
  max-width: 920px;
  margin: auto;
}
.service-heading__sub {
  text-align: center;
}
.service-heading__sub img {
  height: 14px;
}
@media screen and (min-width: 767px) {
  .service-heading__sub img {
    height: 16px;
  }
}
.service-heading__main {
  margin-top: 3px;
  font-size: 28px;
  color: #1a1a1a;
  text-align: center;
}
@media screen and (min-width: 767px) {
  .service-heading__main {
    font-size: 34px;
  }
}
.service-sectionArea__inner {
  padding: 100px 0px;
  overflow: hidden;
}
@media screen and (min-width: 767px) {
  .service-sectionArea__inner {
    padding: 120px 0px;
  }
}
.bg--secondary {
  background: #dff2ff;
}
.base__btnWrap {
  max-width: 320px;
  margin: 60px auto 0;
}
@media screen and (min-width: 767px) {
  .base__btnWrap {
    max-width: 350px;
    margin: 40px auto 0;
  }
}
.base__btnWrap > .c-buttonPrimary {
  padding: 23px 56px 25px 35px;
  line-height: 1;
}
@media screen and (min-width: 767px) {
  .base__btnWrap > .c-buttonPrimary {
    padding: 23px 70px 25px 60px;
  }
}
/* -----------------------------------------------------------------
 Utility
----------------------------------------------------------------- */
.confirmArea.is-open {
  color: #ffc0cb;
}
.confirmArea.is-fixed {
  color: #008000;
}
/* -----------------------------------------------------------------
 Pages
----------------------------------------------------------------- */
.contact-page {
  padding: 165px 20px 100px;
  background: #dff2ff;
}
@media screen and (min-width: 767px) {
  .contact-page {
    padding: 196px 20px 200px;
  }
}
.contact-page__titleWrap {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}
.contact-page__title {
  font-weight: 700;
  font-size: 28px;
  line-height: 1.4;
  text-align: center;
}
@media screen and (min-width: 767px) {
  .contact-page__title {
    font-size: 40px;
  }
}
.contact-page__title > span {
  display: block;
  font-size: 14px;
  line-height: 1.3;
  background: -webkit-gradient(linear, left top, right top, from(#0084fe), to(#1be394));
  background: linear-gradient(90deg, #0084fe 0%, #1be394 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
@media screen and (min-width: 767px) {
  .contact-page__title > span {
    font-size: 16px;
  }
}
.contact-page__formWrap {
  display: none;
}
.contact-page__formWrap.active {
  display: block;
  margin: 45px auto 0;
}
@media screen and (min-width: 767px) {
  .contact-page__formWrap.active {
    width: 100%;
    max-width: 900px;
    margin: 40px auto 0;
  }
}
.contact-page__form {
  padding: 40px 15px;
  background: #fff;
  -webkit-box-shadow: 0px 0px 40px rgba(0, 0, 0, .04);
          box-shadow: 0px 0px 40px rgba(0, 0, 0, .04);
  border-radius: 16px;
}
@media screen and (min-width: 767px) {
  .contact-page__form {
    padding: 60px 15px;
    border-radius: 32px;
  }
}
.contact-page__formTitleWrap {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}
.contact-page__formTitle {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.4;
  color: #111;
  text-align: center;
}
@media screen and (min-width: 767px) {
  .contact-page__formTitle {
    font-size: 22px;
  }
}
.contact-page__formDescriptionWrap {
  width: 100%;
  margin: 16px auto 0;
}
@media screen and (min-width: 767px) {
  .contact-page__formDescriptionWrap {
    max-width: 720px;
  }
}
.contact-page__formDescription {
  font-weight: 400;
  font-size: 15px;
  color: #222;
  line-height: 1.4;
}
@media screen and (min-width: 767px) {
  .contact-page__formDescription {
    font-size: 16px;
    text-align: center;
  }
}
.contact-page__formItems {
  margin: 40px auto 0;
}
@media screen and (min-width: 767px) {
  .contact-page__formItems {
    max-width: 720px;
    margin: 50px auto 0;
  }
}
.contact-page__formItem:not(:first-of-type) {
  margin-top: 24px;
}
@media screen and (min-width: 767px) {
  .contact-page__formItem:not(:first-of-type) {
    margin-top: 32px;
  }
}
.contact-page__formItem--Privacy {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}
@media screen and (min-width: 767px) {
  .contact-page__formItem--Privacy {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
  }
}
.contact-page__formItemLabel {
  display: block;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.4;
  color: #222;
}
.contact-page__formItemLabel > span {
  padding: 1px 8px;
  background: #ec635e;
  border-radius: 19.5px;
  margin-right: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  color: #fff;
}
.contact-page__formItemLabel--any > span {
  background: #a9a9a9;
}
.contact-page__formItemInput {
  width: 100%;
  margin-top: 10px;
  padding: 15px;
  background: #fafafa;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  color: #222;
}
@media screen and (min-width: 767px) {
  .contact-page__formItemInput {
    padding: 13px 16px;
  }
}
.contact-page__formItemInput--textarea {
  height: 180px;
  resize: none;
}
@media screen and (min-width: 767px) {
  .contact-page__formItemInput--textarea {
    height: 200px;
  }
}
.contact-page__formItem {
  margin-top: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  color: #999;
}
.contact-page__formItemCheck {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
@media screen and (min-width: 767px) {
  .contact-page__formItemCheck {
    width: 20px;
    height: 20px;
  }
}
.contact-page__formItemPrivacy {
  font-weight: 400;
  font-size: 15px;
  line-height: 1.4;
  color: #222;
}
@media screen and (min-width: 767px) {
  .contact-page__formItemPrivacy {
    font-size: 16px;
  }
}
.contact-page__formItemPrivacy > a {
  color: #0e73f0;
}
.contact-page__validateMsg {
  display: none;
}
.contact-page__validateMsg.active {
  display: block;
  margin-top: 5px;
}
.contact-page__validateMsg.active > p {
  color: #ff4952;
  font-size: 15px;
  font-weight: 700;
}
.contact-page__validateMsg--privacy.active {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 5px auto 0;
}
.contact-page__formBtnWrap {
  max-width: 305px;
  margin: 40px auto 0;
}
@media screen and (min-width: 767px) {
  .contact-page__formBtnWrap {
    max-width: 390px;
  }
}
.contact-page__confirmWrap {
  display: none;
}
.contact-page__confirmWrap.active {
  display: block;
  width: 100%;
  margin: 45px auto 0;
}
@media screen and (min-width: 767px) {
  .contact-page__confirmWrap.active {
    max-width: 900px;
    margin: 40px auto 0;
  }
}
.contact-page__confirm {
  padding: 40px 15px;
  background: #fff;
  -webkit-box-shadow: 0px 0px 40px rgba(0, 0, 0, .04);
          box-shadow: 0px 0px 40px rgba(0, 0, 0, .04);
  border-radius: 16px;
}
@media screen and (min-width: 767px) {
  .contact-page__confirm {
    padding: 60px 15px 80px;
    border-radius: 32px;
  }
}
.contact-page__detail {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 33px auto 0;
}
@media screen and (min-width: 767px) {
  .contact-page__detail {
    max-width: 720px;
    margin: 51px auto 0;
  }
}
.contact-page__detailItem:not(:first-of-type) {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid #eaeaea;
}
.contact-page__detailTitle {
  font-weight: 700;
  font-size: 14px;
  line-height: 1.4;
  color: #222;
}
.contact-page__detailInfo {
  margin-top: 15px;
  font-size: 16px;
  line-height: 1.4;
  color: #222;
}
.contact-page__confirmBtnWrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  gap: 24px;
  max-width: 305px;
  margin: 40px auto 0;
}
@media screen and (min-width: 767px) {
  .contact-page__confirmBtnWrap {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    gap: 40px;
    max-width: 700px;
    margin: 80px auto 0;
  }
}
.contact-page__backBtn {
  padding: 23px 30px;
  background: #999;
  border-radius: 50px;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
  color: #fff;
}
@media screen and (min-width: 767px) {
  .contact-page__backBtn {
    width: 100%;
    padding: 26px 30px;
    max-width: 270px;
    font-size: 24px;
  }
}
.contact-page__thanksWrap {
  max-width: 900px;
  margin: 40px auto 0;
}
.contact-page__thanks {
  padding: 40px 15px 60px;
  background: #fff;
  -webkit-box-shadow: 0 0 40px rgba(0, 0, 0, .04);
          box-shadow: 0 0 40px rgba(0, 0, 0, .04);
  border-radius: 16px;
}
@media screen and (min-width: 767px) {
  .contact-page__thanks {
    padding: 60px 15px 80px;
    border-radius: 32px;
  }
}
.contact-page__thanksTitleWrap {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}
.contact-page__thanksTitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.4;
  color: #111;
  text-align: center;
}
@media screen and (min-width: 767px) {
  .contact-page__thanksTitle {
    font-size: 28px;
  }
}
.contact-page__thanksTitle > span {
  display: block;
  margin-bottom: 16px;
  font-size: 20px;
}
@media screen and (min-width: 767px) {
  .contact-page__thanksTitle > span {
    font-size: 22px;
  }
}
.contact-page__thanksDescriptionWrap {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 40px auto 0;
}
.contact-page__thanksDescription {
  font-size: 15px;
  line-height: 1.4;
}
@media screen and (min-width: 767px) {
  .contact-page__thanksDescription {
    font-size: 16px;
    text-align: center;
  }
}
.contact-page__thanksDescription:not(:first-of-type) {
  margin-top: 22px;
}
@media screen and (min-width: 767px) {
  .contact-page__thanksDescription:not(:first-of-type) {
    margin-top: 23px;
  }
}
.contact-page__thanksBtnWrap {
  width: 100%;
  max-width: 305px;
  margin: 60px auto 0;
}
@media screen and (min-width: 767px) {
  .contact-page__thanksBtnWrap {
    max-width: 350px;
  }
}
.contact-page__thanksBtnWrap > a {
  padding-right: 67px;
  text-align: center;
}
/* -----------------------------------------------------------------
 Js
----------------------------------------------------------------- */